import React from "react";
import PageMeta from "../components/PageMeta";
import Fragments from "../components/Fragments";

export default function Home() {
  return (
    <>
      <PageMeta title="QCL - About us" path="" />
      <div className="col3 t-col2">
        <h1 className="top">About Us</h1>
        <div className="lead">
          QCL is a privately owned domiciliary care provider.
        </div>
        <p>
          The company was incorporated in 1995 and, since that date, has
          provided over 1.6 million care visits within the London Boroughs of
          Hounslow and Hillingdon.
        </p>
        <p>
          The Directors of QCL were born in Hounslow and have used their
          extensive local knowledge to build a first class, community based
          company.
        </p>
        <p>
          Our centrally located office is ideally placed to control the areas in
          which we work with great precision.
        </p>
        <p>
          QCL’s policy is to recruit from within and as a result, our senior
          administration team all have first hand experience of care delivery.
        </p>
        <p>
          Three members of our office team were so impressed with the service
          provided by QCL to their own family, that they sought employment with
          us.
        </p>
        <p>
          The guiding belief of QCL is that all people should be treated
          equally. Services are delivered in a person-centred way that respects
          age, ethnicity, religion and culture. By adhering to these principles,
          we provide appropriate care that promotes independence through dignity
          and respect.
        </p>
      </div>
      <div className="col3 t-col1 ratio1:1 p-no-main-hpadding p-no-main-bpadding t-no-main-vpadding t-no-main-rpadding">
        <svg viewBox="0 0 1 1" />
        <Fragments.GoogleMaps className="full-width full-height target" />
      </div>
    </>
  );
}
